<template>
  <div>
    <form>
      <b-card>
        <validation-observer ref="accountRules" tag="form">
          <b-row>
            <b-col md="6" class="mb-2">
              <h5 class="mb-0">Basic Details</h5>
              <small class="text-muted">
                Enter User Basic Details.
              </small>
            </b-col>
            <b-col md="6">
              <div class="demo-inline-spacing">
                <b-form-checkbox v-model="data_local.status" switch inline>
                  Status
                </b-form-checkbox>
              </div>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  rules="required"
                >
                  <label class="required-l" for="name">Name</label>
                  <b-form-input
                    id="name"
                    v-model="data_local.name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="User Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <label class="required-l" for="email">Email</label>
                  <b-form-input
                    id="email"
                    v-model="data_local.email"
                    type="email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="user@mail.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="user id"
                  rules="required"
                >
                  <label class="required-l" for="user-id"
                    >User Id</label
                  >
                  <b-form-input
                    id="user-id"
                    v-model="data_local.employee_id"
                    :state="errors.length > 0 ? false : null"
                    placeholder="User Id"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->
            
            <b-col md="6">
              <b-form-group>
                <label class="required-l" for="roles">Roles</label>
                <v-select
                  v-model="data_local.role_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="list_roles"
                  :reduce="(label) => label.code"
                  label="label"
                  :clearable="false"
                  :searchable="false"
                  input-id="role"
                  placeholder="Select Role.."
                />
              </b-form-group>
            </b-col>
            
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="formSubmitted"
              >
                Save
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </b-card>
    </form>

  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import vSelect from "vue-select";
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormRadio,
  BForm,
  BInputGroup,
  BInputGroupPrepend,
  BTabs,
  BTab,
  BListGroup,
  BListGroupItem,
  VBTooltip,
} from "bootstrap-vue";
import { required, email, numeric } from "@validations";
import flatPickr from "vue-flatpickr-component";
import { onUnmounted } from "@vue/composition-api";
import store from "@/store";
import userStoreModule from "../userStoreModule";

export default {
  components: {
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCard,
    BCardHeader,
    BCardTitle,
    BTable,
    BCol,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BFormCheckbox,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BTabs,
    BTab,
    BListGroup,
    BListGroupItem,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  mounted() {
    this.data_local.name = this.$route.query.name;
    if (this.$route.query.dob) {
      this.data_local.date_of_birth = new Date(
        Date.parse(this.$route.query.dob)
      ).toISOString();
    }
    if (this.$route.query.number) {
      this.data_local.mobile = this.$route.query.number;
    }
  },
  data() {
    return {
      required,
      email,
      numeric,
      reportingManagerOptions: [],
      data_local: {
        name: "",
        email: "",
        status: true,
        employee_id: "",
        role_id: "",
      },
      list_roles: [],
    };
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-users";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });
  },
  
  created() {
    this.fetchRolesList();
  },
  methods: {
    resetModal() {
      this.designationName = "";
    },
    fetchRolesList() {
      this.isloading = true;
      this.$store
        .dispatch("app-users/fetchRolesList")
        .then((response) => {
          this.list_roles = response.data.data;
          this.isloading = false;
        })
        .catch((error) => {
          this.isloading = false;
        });
    },
    formSubmitted() {
      this.$store
        .dispatch("app-users/addUser", this.data_local)
        .then((res) => {
          if (res.data.success) {
            if (this.$route.query.id) this.updateStatus(this.$route.query.id);
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "User Added",
            //     icon: "BellIcon",
            //     variant: "success",
            //     text: res.data.message,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'User Added',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
            this.$router.push("/members");
          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Oops! User Adding Failed",
            //     icon: "BellIcon",
            //     variant: "danger",
            //     text: res.data.message,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! User Adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        })
        .catch((error) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! User Adding Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! User Adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    fetchData() {
      this.$http.get("/list/users").then((res) => {
        this.reportingManagerOptions = res.data.data;
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/pages/page-faq.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
